<template>
    <div>
        <button-top v-if="permissions.c" icon="RefreshCcwIcon" tooltip="Sincronizar registros" name="Sincronizar"
            @action="synchronize"></button-top>
        <div class="row justify-content-start mt-1">
            <div class="col-4">
                <b-form-group>
                    <label for="search" class="text-success">{{ label_text }}</label>
                    <b-input-group class="input-group-merge">
                        <b-form-input id="search" v-model="search" class="form-control-merge" name="search" @input="filter"
                            placeholder="" />
                        <b-input-group-append is-text>
                            <feather-icon icon="SearchIcon" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </div>
        <b-table :items="items" :fields="fields" :busy="load">
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                </div>
            </template>
        </b-table>
        <b-row class="mt-1">
            <b-col>
                <b-pagination v-model="current_page" :total-rows="total" :per-page="per_page"
                    aria-controls="my-table"></b-pagination>
            </b-col>
            <b-col>
                <b-form-select class="fl" v-model="per_page" :options="options_per_page"
                    title="Registros por página"></b-form-select>
            </b-col>
            <b-col cols="6"></b-col>
        </b-row>
    </div>
</template>
    
<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BCardText,
        BLink,
    },
    data() {
        return {
            load: true,
            search: '',
            api: "",
            items: [],
            current_page: 1,
            per_page: 5,
            total: 0,
            typing_timer: null,
            options_per_page: [5, 10, 15, 25, 50, 100]
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        async '$route'(to, from) {
            await this.init();
        },

        async current_page(new_value, old_value) {
            await this.get();
        },
        async per_page(new_value, old_value) {
            await this.get();
        },
    },

    methods: {
        async init() {
            this.search = '';
            this.current_page = 1;
            this.per_page = 5;
            this.api = this.$route.meta.breadcrumb[0].api;
            await this.getPermission();
            await this.get();
        },

        async filter() {
            clearTimeout(this.typing_timer);
            this.typing_timer = setTimeout(async () => {
                this.current_page = 1;
                await this.get();
            }, 1000);
        },

        async get() {
            this.load = true;
            this.items = [];
            this.$http
                .get(`administration/integration/${this.api}/index`,
                    {
                        params: {
                            current_page: this.current_page,
                            per_page: this.per_page,
                            filter: this.search,
                        }
                    })
                .then(res => {
                    this.items = res.data.data;
                    this.total = res.data.total;
                })
                .catch(error => {
                    this.notify("Error", error.response.data.message, 'danger')
                }).finally(
                    () => {
                        this.load = false;
                    }
                )
        },

        async synchronize() {
            try {
                this.loading();
                const { message } = (await this.$http.post(`administration/integration/${this.api}/store`)).data;
                this.current_page = 1;
                await this.get();
                this.notify("Proceso satisfactorio.", message, 'primary');
            } catch (err) {
                console.log(err);
                this.notify("Error", err.response.data.message, 'danger')
            } finally {
                this.not_loading();
            }
        }
    },

    computed: {
        label_text() {
            let name = "";
            if (this.api === 'basin') {
                name = "Buscar (Cuenca, Municipio)"
            }

            else if (this.api === 'contract') {
                name = "Buscar (NIT, Organización)"
            }

            else if (this.api === 'dividepole' || this.api === 'third') {
                name = "Buscar (Pais, Departamento)"
            }

            else if (this.api === 'regional') {
                name = "Buscar (Nombre, Departamento)"
            }
            return name;

        },

        fields() {
            let fields = [];
            if (this.api === 'basin') {
                fields = [
                    {
                        key: 'id',
                        label: 'Id. Cuenca',
                    },
                    {
                        key: 'name',
                        label: 'Cuenca',
                    },

                    {
                        key: 'country_name',
                        label: 'Pais',
                    },
                    {
                        key: 'department_name',
                        label: 'Departamento',
                    },

                    {
                        key: 'city_name',
                        label: 'Municipio',
                    },

                ];
            }

            else if (this.api === 'contract') {
                fields = [
                    {
                        key: 'id',
                        label: 'Id. Contrato',
                    },
                    {
                        key: 'name',
                        label: 'Organizacion',
                    },

                    {
                        key: 'start_date',
                        label: 'Fecha Inicio',
                    },
                    {
                        key: 'end_date',
                        label: 'Fecha Fin',
                    },

                    {
                        key: 'nit',
                        label: 'NIT',
                    },

                ];
            }

            else if (this.api === 'dividepole') {
                fields = [
                    {
                        key: 'id',
                        label: 'Id. Divipola',
                        formatter: (value) => `div-${value}`,
                    },
                    {
                        key: 'country_name',
                        label: 'Pais',
                    },
                    {
                        key: 'department_name',
                        label: 'Departamento',
                    },

                    {
                        key: 'city_name',
                        label: 'Municipio',
                    },

                ];
            }

            else if (this.api === 'regional') {
                fields = [
                    {
                        key: 'id',
                        label: 'Id. Regional',
                    },
                    {
                        key: 'name',
                        label: 'Nombre',
                    },

                    {
                        key: 'department_name',
                        label: 'Departamento',
                    },

                    {
                        key: 'city_name',
                        label: 'Municipio',
                    },
                    {
                        key: 'address',
                        label: 'Direccion',
                    },
                ];
            }

            else if (this.api === 'third') {
                fields = [
                    {
                        key: 'id',
                        label: 'Id. Tercero',
                    },
                    {
                        key: 'nit',
                        label: 'NIT',
                    },

                    {
                        key: 'name',
                        label: 'Empresa',
                    },

                    {
                        key: 'country_name',
                        label: 'Pais',
                    },
                    {
                        key: 'department_name',
                        label: 'Departamento',
                    },

                    {
                        key: 'city_name',
                        label: 'Municipio',
                    },

                ];
            }

            return fields;
        }
    }
}
</script>
   
    